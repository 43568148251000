<template>
  <el-container>
    <el-header style="height: auto">
      <el-row type="flex" align="bottom">
        <el-col
          :xs="{ span: 23, offset: 0.5 }"
          :sm="{ span: 22, offset: 1 }"
          :md="{ span: 20, offset: 2 }"
          :lg="{ span: 9, offset: 2 }"
          :xl="{ span: 6, offset: 6 }"
        >
          <div class="logo">
            <img
              width="64px"
              height="64px"
              src="https://res.xmistca.com/image/xmist/xmist-logo.png"
              @click="$router.push({ name: 'index' })"
            />
            <div class="logo-inner">
              <h2>厦门软件职业技术学院-计算机协会</h2>
              <span class="logo-inner-english hidden-md-and-down"
                >Xiamen Institute of Software Technology Computer Association</span
              >
            </div>
          </div>
        </el-col>
        <el-col class="hidden-md-and-down" :lg="{ span: 14, offset: 1 }">
          <el-menu
            mode="horizontal"
            style="border: none"
            text-color="#000000"
            :default-active="$route.meta.active"
            @select="navTo"
          >
            <el-menu-item index="index">主页</el-menu-item>
            <el-menu-item index="office">办公室</el-menu-item>
            <el-menu-item index="technology">技术部</el-menu-item>
            <el-menu-item index="practice">实践部</el-menu-item>
            <el-menu-item index="outreach">外联部</el-menu-item>
            <el-menu-item index="propaganda">宣传部</el-menu-item>
          </el-menu>
        </el-col>

        <el-button
          icon="el-icon-caret-bottom"
          class="hidden-lg-and-up"
          @click="drawer = true"
          circle
        ></el-button>
        <el-drawer
          style="height: 100vh"
          title="厦门软件职业技术学院-计算机协会"
          :visible.sync="drawer"
          direction="ttb"
        >
          <el-tree
            :data="navList"
            :props="defaultProps"
            check-on-click-node
            highlight-current
            @node-click="handleNodeClick"
          ></el-tree>
        </el-drawer>
      </el-row>
    </el-header>
    <el-main style="padding: 0">
      <router-view />
    </el-main>
    <el-footer style="padding: 0">
      <el-row type="flex" justify="space-between" align="start">
        <el-col v-for="item in footerList" :key="item.type">
          <h2>{{ item.type }}</h2>
          <p>{{ item.name }}</p>
          <p v-if="item.content">{{ item.content }}</p>
          <el-image
            v-else
            style="width: 100px; height: 100px"
            :src="$store.state.joinUsImageUrl + item.image"
            :preview-src-list="[$store.state.joinUsImageUrl + item.image]"
          />
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>

<script>
export default {
  name: "index",
  data() {
    return {
      active: "index",
      drawer: false,
      navList: [
        {
          label: "主页",
          index: "index",
        },
        {
          label: "办公室",
          index: "office",
        },
        
        {
          label: "技术部",
          index: "technology",
        },
        {
          label: "实践部",
          index: "practice",
        },
        {
          label: "外联部",
          index: "outreach",
        },
        {
          label: "宣传部",
          index: "propaganda",
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      footerList: [],
    };
  },
  methods: {
    navTo(path) {
      this.$router.push({
        name: path,
      });
    },

    handleNodeClick(data) {
      if (data.index) {
        this.drawer = false;
        this.$router.push({
          name: data.index,
        });
      }
    },
  },
};
</script>

<style>
* {
  font-family: "misans-normal";
}

body {
  margin: 0;
}

/* 超小设备 (手机, 768px 以下屏幕设备) */
@media only screen and (max-width: 768px) {
  * {
    font-size: 16px;
  }
  p {
    line-height: 22px;
  }
  h2 {
    font-size: 18px;
  }
}

/* 小设备 (平板电脑和大型手机，768 像素及以上) */
@media only screen and (min-width: 768px) {
  * {
    font-size: 18px;
  }
  p {
    line-height: 24px;
  }
  h2 {
    font-size: 20px;
  }
}

/* 中型设备（平板电脑，992 像素及以上） */
@media only screen and (min-width: 992px) {
  * {
    font-size: 20px;
  }
  p {
    line-height: 26px;
  }
  h2 {
    font-size: 22px;
  }
}

/* 大型设备（笔记本电脑/台式机，1200 像素及以上） */
@media only screen and (min-width: 1200px) {
  * {
    font-size: 18px;
  }
  p {
    line-height: 24px;
  }
  h2 {
    font-size: 20px;
  }
}

/* 超大型设备（大型笔记本电脑和台式机，1920 像素及以上） */
@media only screen and (min-width: 1920px) {
  * {
    font-size: 18px;
  }
  p {
    line-height: 24px;
  }
  h2 {
    font-size: 20px;
  }
}

.el-header {
  border-bottom: 1px solid #e6e6e6;
  box-shadow: 0 0 10px 1px #e6e6e6;
}

.el-header .el-button {
  position: absolute;
  right: 10px;
  top: 20px;
}

.el-drawer {
  height: 100vh;
}
.logo {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 10px 0;
}
.logo-inner {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.logo-inner-english {
  font-size: 10px;
}
.el-main {
  border-bottom: 1px solid #e6e6e6;
  height: 100%;
}

.el-main::-webkit-scrollbar {
  display: none;
}

.el-footer {
  border-top: 2px solid #d6d6d6;
}

.el-footer .el-row {
  background-color: #e6e6e6;
}

.el-footer .el-col {
  padding: 32px;
}

.el-footer p {
  font-size: 16px;
  font-weight: 900;
}
</style>