import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/404",
    component: () => import("@/views/404"),
    meta: {
      title: "页面未找到 | 厦门软件职业技术学院-计算机协会",
    },
  },
  {
    path: "/",
    name: "index",
    component: () => import("@/views/index"),
    meta: {
      title: "主页 | 厦门软件职业技术学院-计算机协会",
      active: "index",
    },
  },
  {
    path: "/office",
    name: "office",
    component: () => import("@/views/office"),
    meta: {
      title: "办公室 | 厦门软件职业技术学院-计算机协会",
      active: "office",
    },
  },
  {
    path: "/technology",
    name: "technology",
    component: () => import("@/views/technology"),
    meta: {
      title: "技术部 | 厦门软件职业技术学院-计算机协会",
      active: "technology",
    },
  },
  {
    path: "/practice",
    name: "practice",
    component: () => import("@/views/practice"),
    meta: {
      title: "实践部 | 厦门软件职业技术学院-计算机协会",
      active: "practice",
    },
  },
  {
    path: "/outreach",
    name: "outreach",
    component: () => import("@/views/outreach"),
    meta: {
      title: "外联部 | 厦门软件职业技术学院-计算机协会",
      active: "outreach",
    },
  },
  {
    path: "/propaganda",
    name: "propaganda",
    component: () => import("@/views/propaganda"),
    meta: {
      title: "宣传部 | 厦门软件职业技术学院-计算机协会",
      active: "propaganda",
    },
  },
  // 404 page must be placed at the end !!!
  { path: "*", redirect: "/404", hidden: true },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

// 全局后置守卫
router.afterEach((to) => {
  document.title = to.meta.title; //在全局后置守卫中获取路由元信息设置title
});

export default router;
